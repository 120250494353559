import { createRouter, createWebHistory } from "vue-router";
import { LoginCallback } from "@okta/okta-vue"
import multiguard from "vue-router-multiguard";
import { authenticatedGuard, storeGuard } from "@/helpers/routerHelper";

let viewsPath = "./views/";
let componentsPath = "./components/";
let requiresAuth = process.env.VUE_APP_REQUIRE_AUTH || true;

export const router = createRouter({
    history: createWebHistory(`${process.env.BASE_URL}`),
    routes: [
        {
            path: "/index.html",
            redirect: {
                name: "Home"
            }
        },
        {
            path: "/",
            name: "Home",
            redirect: {
                name: "Login"
            }
        },
        {
            path: "/login",
            component: () => import(viewsPath + "Login"),
            children: [
                {
                    path: "",
                    name: "Login",
                    component: () => import(componentsPath + "Login/Okta")
                },
                {
                    path: "success",
                    component: () => import(viewsPath + "PickCity"),
                    meta: {
                        requiresAuth: requiresAuth
                    }
                },
                {
                    path: "pickcity",
                    component: () => import(viewsPath + "PickCity"),
                    meta: {
                        requiresAuth: requiresAuth
                    }
                }
            ]
        },
        {
            path: "/implicit/callback",
            component: LoginCallback,
        },
        {
            path: "/interest",
            name: "Interest",
            component: () => import(viewsPath + "Interest")
        },
        {
            path: "/",
            component: () => import(componentsPath + "layout/HomePageLayout"),
            meta: {
                requiresAuth: requiresAuth
            },
            children: [
                {
                    path: "/home",
                    name: "Homepage",
                    component: () => import(viewsPath + "Homepage")
                },
            ]
        },
        {
            path: "/",
            component: () => import(componentsPath + "layout/PlaceLayout"),
            meta: {
                requiresAuth: requiresAuth
            },
            children: [
                {
                    path: "/doors/:id/selectedTour/:selectedTourId?",
                    name: "Door",
                    component: () => import(componentsPath + "PlacePage/PlacePage"),
                    props: {
                        type: "doors"
                    }
                },
                {
                    path: "/inspirational-places/:id",
                    name: "Inspirationalplace",
                    component: () => import(componentsPath + "PlacePage/PlacePage"),
                    props: {
                        type: "inspirational-places"
                    }
                },
                {
                    path: "/tradezones/:id",
                    name: "Tradezone",
                    component: () => import(componentsPath + "PlacePage/PlacePage"),
                    props: {
                        type: "tradezones"
                    }
                },
                {
                    path: "/partners/:id",
                    name: "Partner",
                    component: () => import(componentsPath + "PlacePage/PlacePage"),
                    props: {
                        type: "partners"
                    }
                },
                {
                    path: "/events/:id",
                    name: "Events",
                    component: () => import(componentsPath + "PlacePage/PlacePage"),
                    props: {
                        type: "events"
                    }
                },
                {
                    path: "/customers/:id",
                    name: "Customer",
                    component: () => import(componentsPath + "SwipePage/Templates/SwipeCustomer"),
                    meta: {
                        requiresAuth: requiresAuth
                    },
                },
            ]
        },
        {
            path: "/",
            component: () => import(componentsPath + "layout/MainLayout"),
            meta: {
                requiresAuth: requiresAuth
            },
            children: [
                {
                    path: "/doors",
                    name: "Doors",
                    redirect: {
                        name: "Home"
                    }
                },
                {
                    path: "/doors/:doorID/feedbacks",
                    name: "DoorFeedbacks",
                    component: () => import(viewsPath + "Feedbacks")
                },
                {
                    path: "/tradezones",
                    name: "Tradezones",
                    redirect: {
                        name: "Home"
                    }
                },
                {
                    path: "/partners",
                    name: "Partners",
                    redirect: {
                        name: "Home"
                    }
                },
                {
                    path: "/user",
                    name: "User",
                    component: () => import(viewsPath + "User")
                },

                {
                    path: "/search",
                    name: "SearchPage",
                    component: () => import(viewsPath + "SearchPage")
                },

                {
                    path: "/map",
                    name: "MapPage",
                    component: () => import(viewsPath + "MapPage")
                },
                {
                    path: "/feedbacks",
                    name: "Feedbacks",
                    component: () => import(viewsPath + "Feedbacks")
                },
                {
                    path: "/campaigns",
                    name: "Campaigns",
                    component: () => import(viewsPath + "Campaigns"),
                    meta: {
                        requiresAuth: requiresAuth
                    }
                },
                {
                    path: "/tour/createTout",
                    name: "CreationTourPage",
                    component: () => import(`${componentsPath}Tour/CreationTourPage`)
                },
                {
                    path: "/tour/editTout/:tourId",
                    name: "EditingTourPage",
                    props: (route) => ({
                        tourId: route.params.tourId,
                    }),
                    component: () => import(`${componentsPath}Tour/EditingTourPage`)
                },
                {
                    path: "/tour/preBuilt/:tourId",
                    name: "PreBuiltTourPage",
                    props: (route) => ({
                        tourId: route.params.tourId,
                    }),
                    component: () => import(`${componentsPath}Tour/PreBuiltTourPage`)
                },
                {
                    path: "/tour/:tourId",
                    name: "TourPage",
                    props: (route) => ({
                        tourId: route.params.tourId,
                    }),
                    component: () => import(`${componentsPath}Tour/TourPage`)
                },
            ]
        },
        {
            path: "/campaigns/:idType/id/:id/active/:active",
            name: "Campaign",
            component: () => import(componentsPath + "SwipePage/Templates/SwipeCampaign"),
            meta: {
                requiresAuth: requiresAuth
            }
        },
        {
            path: "/customers",
            name: "Customers",
            redirect: {
                name: "Home"
            }
        },
    ]
});

router.beforeEach(multiguard([authenticatedGuard, storeGuard]));
