import { mapGetters, mapState, mapActions, mapMutations } from "vuex";
import VLoading from "@/components/ui/VLoading";
import { TransitionFade } from "@morev/vue-transitions";

/**
 * Mixin used for General purpose:
 *  - Data loading,
 *  - Loader showing
 */
export default {
    components: {
        TransitionFade,
        VLoading
    },
    data() {
        return {
            loadingMut: false
        };
    },
    /**
     * Ensure the loader will not keep shown after component destroy
     */
    beforeUnmount() {
        if (this.loadingMut) this.$store.commit("tools/hideAppLoader", this.$vnode?.tag);
    },
    computed: {
        ...mapState({
            currentCity: state => state.city.currentCity,
            userInfo: state => state.user.userInfo,
        }),
        ...mapGetters("user", ["isUserAdmin", "isUserGuide"]),
        ...mapGetters("interest", ["hasAskedInterests"]),
        ...mapGetters("tools", ["isDebug"]),
        userId() {
            return this.userInfo ? this.userInfo.id : null;
        },
        /**
         * CSS background attribute with jpeg using field IMAGE from currentCity
         * @returns {string}
         */
        cityImage() {
            return this.currentCity && this.currentCity.image ? "url(" + this.currentCity.image + ")" : "none";
        },
        /**
         * CSS background attribute with jpeg using field BACKGROUND from currentCity
         * @returns {string}
         */
        cityBackground() {
            return this.currentCity && this.currentCity.background
                ? "url(" + this.currentCity.background + ")"
                : "none";
        },
        /**
         * Show/hide Global App loader
         */
        loading: {
            get() {
                return this.loadingMut;
            },
            set(val) {
                this.loadingMut = val;
                if (val) {
                    this.$store.commit("tools/showAppLoader", this.$vnode?.tag);
                } else {
                    this.$store.commit("tools/hideAppLoader", this.$vnode?.tag);
                }
            }
        },
        /**
         * Current user as one and only one city allowed
         * @returns {boolean}
         */
        onlyOneCityAllowed() {
            return !!(this.userInfo && this.userInfo.cities.length === 1);
        }
    },
    methods: {
        ...mapMutations("city", ["setCurrentCity"]),
        ...mapActions("city", ["loadCityById"]),
        /**
         * Checks if the city has the feature active
         * @param feature: name of the feature to check
         * @returns boolean
         */
        activeFeature(feature) {
            return this.currentCity ? this.currentCity.features[feature] : false;
        }
    }
};
