import { store } from "../store";
import { oktaAuth } from "../auth"

const authenticatedGuard = async (to, from, next) => {
    try {
        if (to.path.includes("/login") && !to.path.includes("/success")) {
            if (await oktaAuth.isAuthenticated()) {
                next({ path: "/login/success" });
            } else {
                next();
            }
        } else if (to.path.includes("/logout")) {
            try {
                await oktaAuth.signOut();
            } catch (error) {
                //eslint-disable-next-line
                console.log(error);
            }
            next({ path: "/login" });
        } else if (to.matched.some(record => record.meta.requiresAuth)) {
            if (!(await oktaAuth.isAuthenticated())) {
                next({ path: "/login" });
            } else {
                next();
            }
        } else {
            next();
        }
    } catch (error) {
        //eslint-disable-next-line
        console.log(error);
        next();
    }
};

const storeGuard = async (to, from, next) => {
    try {
        if (to.path.includes("/login") || to.path.includes("/logout")) {
            await store.dispatch("reset");
        }
        if (to.matched.some(record => record.meta.requiresAuth)) {
            if (!store.state.loaded) await store.dispatch("load");
            if (!window.api) store.commit("initApi");
        }
    } catch (error) {
        //eslint-disable-next-line
        console.log(error);
    } finally {
        next();
        store.commit("tools/clearAppLoader");
    }
};

export { authenticatedGuard, storeGuard };
