const getDefaultState = () => {
    return {
        answeredInterestIds: []
    };
};
const state = getDefaultState();

const getters = {
    hasAskedInterests: (state, getters, rootState) => {
        if (!(rootState.user && rootState.user.userInfo && rootState.user.userInfo.askPersonalInterests)) return false;
        if (Array.isArray(rootState.user.userInfo.askPersonalInterests)) return false;
        if (state.answeredInterestIds.indexOf(rootState.user.userInfo.askPersonalInterests.id) > -1) return false;

        return true;
    }
};

const mutations = {
    addInterestsAnswered(state, interestsId) {
        state.answeredInterestIds.push(interestsId);
    },
    reset(state) {
        Object.assign(state, getDefaultState());
    }
};

const actions = {
    async loadFormInterestsByCity({ rootState }, { cityId } = {}) {
        cityId = cityId || rootState.city.currentCity.id;
        return api.request("interests", {
            "city.id": cityId
        });
    },
    // eslint-disable-next-line
    // async updateInterest({}, { id, steps }) {
    // NKFRPWA-166/NKFRPWA-109: bug about loader after removing Personal Interest
    //     return api.put("personal-interests/" + id, steps);
    // }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
    modules: {}
};
