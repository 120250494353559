const getDefaultState = () => {
    return {
        loadingStack: [],
        showAppLoader: false,
    };
};
const state = getDefaultState();

const getters = {
    isDebug: () => {
        return !!localStorage.getItem("debug");
    }
};

const mutations = {
    showAppLoader(state, text) {
        state.loadingStack.push(text);
        state.showAppLoader = true;
    },
    hideAppLoader(state, text) {
        const i = state.loadingStack.findIndex(val => val === text);
        if (i > -1) {
            state.loadingStack.splice(i, 1);
        }
        state.showAppLoader = state.loadingStack.length > 0;
    },
    clearAppLoader(state) {
        state.loadingStack = [];
        state.showAppLoader = false;
    },
    setIsDebug(state, val) {
        state.isDebug = val;
    },
    reset(state) {
        Object.assign(state, getDefaultState());
    },
};

const actions = {};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
