<template>
    <div id="app">
        <router-view :key="$route.fullPath" />
        <TransitionFade :duration="400">
            <VLoading v-show="showAppLoader" class="app-loading" />
        </TransitionFade>
    </div>
</template>

<script>
import { mapState } from "vuex";
import $ from "jquery";
import { TransitionFade } from "@morev/vue-transitions";
import VLoading from "@/components/ui/VLoading";
import GeneralMixin from "@/components/GeneralMixin";

export default {
    name: "App",
    mixins: [GeneralMixin],
    components: {
        TransitionFade,
        VLoading
    },
    data() {
        return {
            isLandscape: false
        };
    },
    computed: {
        ...mapState({
            showAppLoader: state => state.tools.showAppLoader
        })
    },
    mounted() {
        $(window).on("load", () => {
            this.isLandscape = window.matchMedia("(orientation: landscape)").matches;
        });

        $(window).on("orientationchange", () => {
            this.isLandscape = !this.isLandscape;
        });
    }
};
</script>

<style lang="scss">
@keyframes landscape {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(-20deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.isLandscape {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $white;
    z-index: 1000;
    opacity: 1;
    transform: scale(1);
    @include transition(all, ease-in, 0.3s);

    div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        h2 {
            margin-bottom: 10px;
        }

        p {
            margin-bottom: 30px;
        }

        .phone {
            margin-bottom: 30px;
            width: 24px;
            transform: rotate(-90deg);
            animation: landscape 1s ease-in-out infinite;
        }
    }
}

.modal-enter,
.modal-leave-to {
    opacity: 0;
    transform: scale(1.1);
}

.app-loading {
    position: absolute;
    z-index: 100;
}
</style>
