import { OktaAuth } from '@okta/okta-auth-js'
import oktaConfig from './okta.config'

export const oktaAuth = new OktaAuth({
  issuer: oktaConfig.oidc.issuer,
  clientId: oktaConfig.oidc.clientId,
  redirectUri: oktaConfig.oidc.redirectUri,
  scope: oktaConfig.oidc.scope,
  pkce: false
})
