import { createApp } from "vue";
import App from "./App.vue";
import { router } from "./router";
import { store } from "./store";
import { i18n } from "./i18n";
import Vue3TouchEvents from "vue3-touch-events";
import VueTransitions from "@morev/vue-transitions";
import OktaVue from "@okta/okta-vue"
import { oktaAuth } from "./auth"
import "@morev/vue-transitions/styles";

createApp(App)
.use(router)
.use(OktaVue, { oktaAuth })
.use(i18n)
.use(store)
.use(VueTransitions, {
  // Plugin options (optional)
})
.use(Vue3TouchEvents, {
  swipeTolerance: 100,
})
.mount('#app');
