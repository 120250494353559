export default {
  "campaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaigns"])},
  "menu": {
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "feedbacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
    "homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore"])}
  },
  "header": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A new way to discover our Retail"])},
    "discoverButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover the city"])}
  },
  "city": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is "])},
    "selectAnother": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change city"])}
  },
  "env": {
    "android": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Android"])},
    "ios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IOS"])}
  },
  "errors": {
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to home"])},
    "noConnection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Internet connection."])},
    "placeNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, the place can't be found."])},
    "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no results that match your search."])},
    "minChar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There should be at least 3 characters."])},
    "wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, something went wrong."])}
  },
  "explore": {
    "campaigns": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All campaigns"])},
      "filters": {
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By date"])},
        "door": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By door"])}
      }
    },
    "categories": {
      "tradeZones": {
        "query": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tradezones"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trade zones"])},
        "singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trade zone"])}
      },
      "inspirationalPlaces": {
        "query": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inspirational-places"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inspirational Places"])},
        "singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inspirational Place"])}
      },
      "physicalDoors": {
        "query": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["doors"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["physical"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All stores"])},
        "singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["store"])}
      },
      "mindsets": {
        "query": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mindsets"])},
        "singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field of Play"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fields of Play"])}
      },
      "highlightedDestinations": {
        "query": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["highlights"])}
      }
    },
    "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore by"])},
    "search": {
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for places"])},
      "defaultPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for ..."])}
    },
    "tradeZonesSearch": {
      "doors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doors:"])}
    }
  },
  "feedback": {
    "addPicture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add a picture"])},
    "titleBuilder": {
      "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
      "suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'s feedback"])}
    },
    "allFeedbacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All feedback"])},
    "allMyFeedbacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All my feedback"])},
    "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is an alert"])},
    "attached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attached files"])},
    "back": {
      "door": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See the door"])},
      "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See the door"])}
    },
    "by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By"])},
    "closedTours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed tours"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "editDock": {
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
      "deleteAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete All"])}
    },
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
    "feedbackBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
    "noFeedbacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no feedback"])},
    "noTours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Others"])},
    "orderByDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "orderByDoor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place"])},
    "orderByParticipants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants"])},
    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No comment registered"])},
    "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
    "tourFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tour Feedback"])},
    "confirmShareCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback has been saved"])},
    "confirmShareButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share"])}
  },
  "install": {
    "androidInstall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the link below <br/><b>« Install on your homescreen »</b>"])},
    "androidSecondInstall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Or, click on the options button in your browser and click on <b>Add to homescreen</b>"])},
    "androidValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate the installation in the popup"])},
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See how to do it on "])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In order to access all the functionalities of this platform and to ensure an optimal use, we recommend you to install it on your home screen by your browser options"])},
    "iosAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finally click on <b>Add</b> in the right corner"])},
    "iosAddHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Then scroll the last icon lines at the bottom of the pop-up to find and click on <b>Add to homescreen</b>"])},
    "iosShare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on your central icon <b>Share</b> in the dock at the bottom of your screen"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One more step to go!"])}
  },
  "interest": {
    "theme": {
      "campaigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaigns"])},
      "channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channels"])},
      "mindsets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nike categories"])},
      "tradezones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tradezones"])}
    },
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete my interest"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What will you be interested in during your journey"])},
    "hi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hi"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add my interests later"])},
    "thanks": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your answers will help the local team to prepare the best City Tour for you"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you"])}
    }
  },
  "inspiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inspirational places around"])},
  "landscape": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In order to enjoy the full experience, please use your app in portrait."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turn your phone vertically."])}
  },
  "logged": {
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start my exploration"])},
    "selectCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a city"])},
    "selectAnother": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select another city"])},
    "searchForCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for a city"])},
    "description": {
      "pleaseSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a city."])},
      "selectedCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have selected "])},
      "citySelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City selection"])}
    },
    "pickCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick the city"])},
    "noCityAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have access to any city, please contact your administrator"])},
    "noCityAllowedBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to Sign in"])},
    "welcomeInstructions": {
      "0": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for joining us."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are now going to take you through the key features of this app.<br/> Help us to get it right!<br/> The more you tell us about your app experience, the better we can make it."])}
      },
      "1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggested Tours"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The city team have created relevant retail tours, so you can<br/><br/><ul><li>Schedule it at your own convenience</li><li>Follow the tour yourself</li><li>Invite Nike people to join</li><li>Modify the tour if needed</li>"])}
      },
      "2": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a city tour"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>Enter Tour name, date and visitors</li><li>Ask for visitor’s interests if needed</li><li>Go to “in preparation tour” to complete the program</li><li>Select either a suggested tour or create a new one</li><li>Schedule each door within the tour time range</li>"])}
      }
    }
  },
  "login": {
    "primaryauth": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A new way to discover our Retail"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
      "username": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nike ID"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your Nike ID"])}
      },
      "password": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your super secret password"])}
      }
    },
    "error": {
      "username": {
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a Nike ID"])}
      },
      "password": {
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a password"])}
      }
    },
    "errors": {
      "E0000004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in failed!"])}
    }
  },
  "map": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore on the map"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select stores to display details"])},
    "filters": {
      "catchphrase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What are you interested in?"])},
      "ctaReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
      "ctaSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate"])},
      "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filters"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter your map"])}
    },
    "interests": {
      "campaigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaigns"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partners"])},
      "customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fields of Play"])},
      "inspirationalPlaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inspirational Places"])},
      "tradeZones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trade zones"])}
    },
    "search": {
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for place"])}
    }
  },
  "navigation": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["back"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["close"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map"])},
    "swipeUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swipe up"])},
    "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visit website"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])}
  },
  "overview": {
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["overview"])},
    "customer": {
      "startTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who are"])},
      "endTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["consumers"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More about"])}
  },
  "place": {
    "campaigns": {
      "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find below the campaigns launched in this store and discover more of each of them."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All campaigns"])}
    },
    "doors": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stores nearby"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Around this store"])},
      "around": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Stores"])},
      "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore for more"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here are the stores near this location"])}
    },
    "keyData": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key data"])},
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel"])},
      "sqm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total SQM"])},
      "marketShare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market Share"])},
      "shareOfBusiness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share of business"])},
      "shareOfBusinessJordanWeight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jordan"])},
      "shareOfBusinessNikeWeight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nike"])},
      "nikeMarketStoreValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nike"])},
      "jordanMarketStoreValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jordan"])}
    },
    "inspirations": {
      "around": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inspirational places around"])},
      "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover all those places"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innovative and disruptive concepts that are bringing something new to the customer experience while exceeding expectations"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Around this store"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#InspirationalPlaces"])}
    },
    "ipType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ip"])},
    "lastUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last update"])},
    "mindset": {
      "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you know"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who buys there?"])}
    },
    "revenue": {
      "segment": {
        "CAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fields of play"])},
        "GE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
        "PE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PE"])}
      },
      "labels": {
        "FW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Footwear"])},
        "AP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apparel"])},
        "EQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equipment"])},
        "NKWM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Women"])},
        "NKMN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Men"])},
        "NKKD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kids"])},
        "JDWM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Women"])},
        "JDMN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Men"])},
        "JDKD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kids"])}
      },
      "legend": {
        "nike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nike"])},
        "jordan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jordan"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenue split in %"])}
    },
    "doorType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e"])},
    "openingTimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opening hours:"])},
    "mustWinDoor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must win door"])}
  },
  "tour": {
    "allTours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All my City Tours"])},
    "cta": {
      "createTour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a tour"])},
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new Tour"])},
      "editProgram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit the program"])},
      "thankYouPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set up thank you page"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Tour"])}
    },
    "delete": {
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure ?"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes delete"])}
    },
    "currently": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your personalized Tour"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get ready for"])}
    },
    "create": {
      "ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ask for this participant's interests"])},
      "alreadyAsked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This participant's interests have been asked"])},
      "addDoors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Doors"])},
      "addParticipant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ Add a new participant"])},
      "addPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ Add a place"])},
      "button": {
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])}
      },
      "backToHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to home"])},
      "programMyTour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program my tour"])},
      "programMyCityTour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program my City Tour"])},
      "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select stores and schedule"])},
      "createProgram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new Tour"])},
      "createTour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a Tour"])},
      "completeProgram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete the program"])},
      "findStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find a store"])},
      "filterDoors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter your doors"])},
      "inputs": {
        "titlePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a name for this Tour"])},
        "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date"])},
        "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date"])},
        "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search people ( name or email )"])},
        "later": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add participants later"])}
      },
      "interests": {
        "interestText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which of those participants do you want to let indicate their interests?"])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None of them"])},
        "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Invitation"])}
      },
      "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
      "lastCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One last check"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a City Tour"])},
      "startFromExistant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ Start from a pre-built tour"])},
      "findOurSuggestedTours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find our suggested tours"])},
      "participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participant"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants"])},
      "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place"])},
      "places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Places"])},
      "participantsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose your participants"])},
      "recapDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An invitation has been sent to every participant and you will be notified by email."])},
      "sendInvitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send invites and publish"])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the estimated dates and the participants"])},
      "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An invitation has been sent to every selected participant."])},
      "summaryWithoutParticipants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will be notified by email."])},
      "selectModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a Suggested Tour"])},
      "selectThisModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select this tour"])},
      "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate"])},
      "weAreGood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It seems we are good ! 🎉"])},
      "wellDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Well done 🎉"])},
      "preBultByFieldaOfPlay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["by Fields of Play"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "completeCityTour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete the City Tour"])},
      "addStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ Add a store"])},
      "saveForLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save for later"])},
      "tourSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An invitation has been sent to every participant and you will be notified by email"])},
      "tourSavedInDraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can retrieve your draft on the Home screen and in your profile"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])}
    },
    "edit": {
      "minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min"])},
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
      "outsideBounds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door visit time is outside of tour bounds."])},
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Start: "])},
      "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" End: "])},
      "overlap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some visits are overlapping."])},
      "overlapName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This visit is overlapping with: "])},
      "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate"])},
      "viewDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View details"])}
    },
    "tourEnd": {
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
      "endTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Tour has ended"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The tour has come to an end. You can now thank the participants and send them a short recap."])},
      "messageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you message"])},
      "thankYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you!"])},
      "thankYouSimple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you"])},
      "forAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for all participants"])},
      "editMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit this defaut message"])},
      "keyFacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some keyfacts"])},
      "storeVisited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["doors visited"])},
      "storesVisited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stores visited"])},
      "aboutCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["about this city Tour"])},
      "validatePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate this page"])},
      "PrevisualizePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previsualize this page"])},
      "pageCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your page has been created"])},
      "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A notification has been sent to every participant and you will be notified by email"])},
      "previsualize": {
        "thankYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you!"])},
        "someKeyfacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some keyfacts"])},
        "aboutTour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["about your city Tour"])},
        "visitorFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visitors' feedback"])},
        "feedBackDoor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How many feedback per Door?"])},
        "tripRating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How was your trip?"])},
        "emailCityFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" City tour feedback"])},
        "evaluateTrip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluate your trip now"])},
        "evaluateApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave your feedback here"])},
        "evaluateValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send it"])},
        "validateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate your message"])},
        "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OR"])},
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any comments?"])},
        "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])}
      },
      "edit": {
        "personalisedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalised message"])},
        "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate this message"])},
        "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make it default"])},
        "keyFactType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keyfact type"])},
        "enterValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a value"])},
        "addFact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add this fact"])}
      }
    },
    "finish": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your personalized Tour"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The tour is over"])}
    },
    "filters": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
      "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed"])},
      "createdByMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created by me"])}
    },
    "going": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One Tour in preparation"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In preparation"])}
    },
    "guideHomepage": {
      "toursAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available tours"])}
    },
    "no": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Tour available"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently"])}
    },
    "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["participants"])},
    "returnHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return Home"])},
    "status": {
      "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed"])},
      "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted"])},
      "planned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planned"])},
      "onGoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongoing"])}
    },
    "preBuiltTours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-built tours"])}
  },
  "user": {
    "changeCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change the city"])},
    "emailBug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retail Tour app Bug"])},
    "emailSuggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retail Tour app Suggestion"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "nike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nike"])},
    "myTours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Tours"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "userSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My account"])},
    "interest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Interests"])},
    "reportBug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report a bug"])},
    "suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send a suggestion"])},
    "noTours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no tours for this city"])}
  },
  "events": {
    "eventsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upcoming events in the City"])}
  }
}