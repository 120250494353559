import { oktaAuth } from "../../auth"

const getDefaultState = () => {
    return {
        oktaUser: null,
        userInfo: null,
        accessToken: null,
        allUsers: []
    };
};

const state = getDefaultState();

const getters = {
    isUserAdmin: state => {
        return (
            state.userInfo &&
            state.userInfo.roles &&
            Object.values(state.userInfo.roles).some(role => ["ROLE_ADMIN", "ROLE_SUPER_ADMIN"].includes(role))
        );
    },
    isUserGuide: state => {
        return !!(state.userInfo && state.userInfo.roles && Object.values(state.userInfo.roles).includes("ROLE_GUIDE"));
    }
};

const mutations = {
    setAuth(state, { oktaUser, accessToken }) {
        state.accessToken = accessToken;
        state.oktaUser = oktaUser;
    },
    setFirstVisit(state, val) {
        state.userInfo.instruction = val;
    },
    setUserInfo(state, userInfo) {
        state.userInfo = userInfo;
    },
    setAccessToken(state, val) {
        state.accessToken = val;
    },
    setAllUsers(state, users) {
        state.allUsers = users;
    },
    reset(state) {
        Object.assign(state, getDefaultState());
    }
};

const actions = {
    async load({ commit, dispatch }) {
        const [oktaUser, accessToken] = await Promise.all([
            oktaAuth.getUser(),
            oktaAuth.getAccessToken()
        ]);

        commit("setAuth", { oktaUser, accessToken });
        if (!window.api) {
            commit("initApi", null, { root: true });
        }

        const result = await dispatch("getUserByMail", oktaUser.email);

        const user =
            result.length > 0
                ? result[0]
                : await dispatch("save", {
                      nikeId: oktaUser.sub,
                      username: oktaUser.sub,
                      email: oktaUser.email,
                      firstname: oktaUser.given_name,
                      lastname: oktaUser.family_name
                  });

        commit("setUserInfo", user);

        // not waiting
        dispatch("update", { user: { id: user.id }, accessToken });

        return user;
    },

    async reset({ commit }) {
        commit("reset");
    },

    // eslint-disable-next-line
    async save({}, user) {
        return api.post("users", {
            nikeId: user.nikeId,
            username: user.nikeId,
            email: user.email,
            firstname: user.firstname,
            lastname: user.lastname
        });
    },

    // eslint-disable-next-line
    async update({}, { user, accessToken }) {
        const token = accessToken;

        return api.put("users/" + user.id, { token });
    },

    async loadAllUsers({ commit, state }) {
        if (!state.allUsers.length) {
            commit("setAllUsers", await api.request("users", {}));
        }
        return state.allUsers;
    },

    async updateUserMessage({ commit }, params) {
        const user = params.user;
        const interests = [];
        // NKFRPWA-166/NKFRPWA-109: bug about loader after removing Personal Interest
        // if (user.personalInterests) {
        //     for (let i = 0; i < user.personalInterests.length; i++) {
        //         interests.push("/api/personal-interests/" + user.personalInterests[i].id);
        //     }
        // }

        const response = api.put("users/" + params.user.id, {
            askPersonalInterests: user.askPersonalInterests,
            createdAt: user.createdAt,
            email: user.email,
            fullname: user.fullname,
            id: user.id,
            nikeId: user.nikeId,
            personalInterests: interests,
            roles: user.roles,
            thanks: params.message,
            username: user.username
        });
        commit("setUserInfo", response.data);
        return response.data;
    },

    // eslint-disable-next-line
    async getUserByMail({}, email) {
        return api.request("users", {
            email: email
        });
    },
    // eslint-disable-next-line
    async getUserById({}, id) {
        return api.request("users/" + id, {});
    },

    async userFinishedInstructions({ commit }, { userId }) {
        return api.put("users/" + userId, { instruction: false }).then(() => {
            commit("setFirstVisit", false);
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
    modules: {}
};
