<template>
    <div>
        <span class="ico-loading" aria-hidden="true"></span>
        <span class="visuallyhidden">{{ $t("navigation.loading") }}</span>
    </div>
</template>

<script>
export default {
    name: "VLoading"
};
</script>

<style lang="scss">
.ico-loading {
    border: 4px solid $grey;
    border-top: 4px solid $primary-color;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
    position: fixed;
    top: 50%;
    left: 45%;
}
</style>
