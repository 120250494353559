import axios from "axios";
import { cacheAdapterEnhancer, throttleAdapterEnhancer } from "axios-extensions";

const hostname = process.env.VUE_APP_API_URL || window.location.hostname.replace("www", "api");
const baseUrl = "https://" + hostname;

/**
 * Creates a specific Axios instance used for all API requests.
 */
const axiosInstance = axios.create({
    baseURL: baseUrl,
    adapter: throttleAdapterEnhancer(cacheAdapterEnhancer(axios.defaults.adapter))
});

export default function(accessToken) {
    return {
        /**
         * Manages API  get requests and executes given callbacks.
         * @param route String
         * @param params Object
         * @param successCallback function
         * @param errorCallback function
         * @param deep Int : Max pagination, default 10
         */
        request(
            route,
            params = {
                cache: true
            },
            successCallback = result => Promise.resolve(result),
            errorCallback = error => Promise.reject(error),
            deep = 10
        ) {
    
            const { cache = true, ...rest } = params

            // Recursion stops when the number of pages is reached
            if (deep < 1) {
                return successCallback();
            }
            return axiosInstance
                .get("api/" + route, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + accessToken,
                    },
                    params: rest,
                    cache
                })
                .then(response => {
                    if (response.data["hydra:member"]) {
                        if (response.data["hydra:view"] && response.data["hydra:view"]["hydra:next"]) {
                            if (!params.page) {
                                params.page = 2;
                            } else {
                                params.page++;
                            }
                            return this.request(
                                route,
                                params,
                                subResponse => {
                                    return successCallback(
                                        subResponse
                                            ? response.data["hydra:member"].concat(subResponse)
                                            : response.data["hydra:member"]
                                    );
                                },
                                subError => {
                                    return errorCallback(subError);
                                },
                                --deep
                            );
                        } else {
                            return successCallback(response.data["hydra:member"]);
                        }
                    } else {
                        return successCallback(response.data);
                    }
                })
                .catch(error => {
                    let result;

                    if (error.response && error.response.status) {
                        result = errorCallback(error.response.status);
                    } else {
                        result = errorCallback(error);
                    }

                    return result;
                });
        },

        /**
         * Manages API post requests and executes given callbacks.
         * @param route String
         * @param data Object
         * @param successCallback function
         * @param errorCallback function
         * @param type String
         */
        post(
            route,
            data,
            successCallback = result => Promise.resolve(result),
            errorCallback = error => Promise.reject(error),
            type
        ) {
            return axiosInstance
                .post("api/" + route, data, {
                    headers: {
                        "Content-Type": type ? type : "application/json",
                        Authorization: "Bearer " + accessToken
                    }
                })
                .then(function(response) {
                    return response.data["hydra:member"]
                        ? successCallback(response.data["hydra:member"])
                        : successCallback(response.data);
                })
                .catch(function(error) {
                    let result;

                    if (error.response && error.response.status) {
                        result = errorCallback(error.response.status);
                    } else {
                        result = errorCallback(error);
                    }

                    return result;
                });
        },
        /**
         * Manages API put requests and executes given callbacks.
         * @param route String
         * @param data Object
         * @param successCallback function
         * @param errorCallback function
         */
        put(
            route,
            data,
            successCallback = result => Promise.resolve(result),
            errorCallback = error => Promise.reject(error)
        ) {
            return axiosInstance
                .put("api/" + route, data, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + accessToken
                    }
                })
                .then(function(response) {
                    return successCallback(response);
                })
                .catch(function(error) {
                    let result;

                    if (error.response && error.response.status) {
                        result = errorCallback(error.response.status);
                    } else {
                        result = errorCallback(error);
                    }
                    return result;
                });
        },
        /**
         * Manages API delete requests and executes given callbacks.
         * @param route String
         * @param params Object
         * @param successCallback function
         * @param errorCallback function
         */
        delete(
            route,
            params,
            successCallback = result => Promise.resolve(result),
            errorCallback = error => Promise.reject(error)
        ) {
            return axiosInstance
                .delete("api/" + route + "/" + params.id, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + accessToken
                    }
                })
                .then(response => {
                    return successCallback(response);
                })
                .catch(function(error) {
                    let result;

                    if (error.response && error.response.status) {
                        result = errorCallback(error.response.status);
                    } else {
                        result = errorCallback(error);
                    }
                    return result;
                });
        }
    };
}
