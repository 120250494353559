import { reactive } from 'vue'
import { Place } from "../sanitize";
const getDefaultState = () => {
    return {
        doors: [],
        tradezones: [],
        partners: [],
        resumed: {
            doors: null,
            tradezones: null,
            partners: null,
            campaigns: null,
            mindsets: null
        },
        mindsets: [],
        ips: [],
        events: [],
        ['inspirational-places']: []
    };
};
const state = getDefaultState();

const getters = {
    getPlacesResumed: state => type => {
        return state.resumed[type];
    },
    getPlace: state => params => {
        if (!state[params.type]) return null;
        return state[params.type].find(place => place.id === params.id);
    }
};

const mutations = {
    setPlacesResumed(state, params) {
        state.resumed = reactive({
            ...state.resumed,
            [params.type]: params.places
        });
    },
    setPlace(state, params) {
        if (params.type === "inspirational-places") {
            state.ips.push(params.place);
        }
        state[params.type].push(Place(params.place, params.id, params.type));
    },
    reset(state) {
        Object.assign(state, getDefaultState());
    }
};

const actions = {
    async loadPlacesResumedByCity({ commit, getters, rootState }, { cityId, type } = {}) {
        cityId = cityId || rootState.city.currentCity.id;

        if (!getters.getPlacesResumed(type)) {
            commit("setPlacesResumed", {
                type,
                places: await api.request(type, { "city.id": cityId, "cities.id": cityId })
            });
        }

        return getters.getPlacesResumed(type);
    },

    async loadPlacesResumedWithFiltersByCity({ rootState }, { type, params, filters }) {
        const cityId = params.cityId || rootState.city.currentCity.id;

        if (type !== "inspirational-places") {
            return await api.request(
                type,
                { ...params, "cities.id": cityId, "city.id": cityId, cache: false },
                undefined,
                undefined,
                10
            );
        } else {
            if (filters) {
                return await api.request("inspirational-places", { ...params }, undefined, undefined, 20);
            }

            return await api.request("cities/" + cityId + "/inspirational-places", {}, undefined, undefined, 20);
        }
    },
    async loadPlace({ commit, getters }, params) {
        const place = await api.request(`${params.type}/${params.id}`, {
            cache: false
        });

        if (place.tradezone) {
            const ipsInTradezone = await this.dispatch("places/loadPlacesResumedWithFiltersByCity", {
                type: "inspirational-places",
                params: { "tradezone.id": place.tradezone.id },
                filters: true
            });

            if (place.inspirationalPlaces) {
                place.inspirationalPlaces = [...place.inspirationalPlaces, ...ipsInTradezone].filter(
                    (item, index, self) => self.findIndex(i => i.loc === item.loc && i.id === item.id) === index
                );
            }
        }

        commit("setPlace", {
            id: params.id,
            type: params.type,
            place: place
        });

        return getters.getPlace(params);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
    modules: {}
};
