const getDefaultState = () => {
    return {
        // all the feedbacks
        feedbacks: [],
        // feedbacks ids ordered by doors id
        feedbacksDoors: {},
        // feedbacks ids ordered by tours id
        feedbacksTour: {},
        // feedback arrays ordered by their tour ID
        feedbacksByTour: [],
        // feedbacks count
        feedbackTypes: [],
        // doors feedbacks count
        doorFeedbackNumbers: [],
    };
};
const state = getDefaultState();

const getters = {
    getFeedbackById: state => id => {
        return state.feedbacks ? state.feedbacks.find(feedback => feedback.id === id) : null;
    },
    getFeedbacksByDoor: (state, getters) => doorId => {
        let feedbacksIds = state.feedbacksDoors[doorId];
        let result = [];
        if (feedbacksIds) {
            for (var i = 0; i < feedbacksIds.length; i++) {
                let feedback = getters.getFeedbackById(feedbacksIds[i]);
                if (feedback) {
                    result.push(feedback);
                }
            }
        }

        return result;
    },
    getFeedbacksByTour: state => tourID => {
        const element = state.feedbacksByTour.find(tour => tour.tourID === tourID);
        return element ? element.feedbacks : undefined;
    },
    getFeedbacksByAuthor: (state, getters) => (authorId, doorId) => {
        const feedbacks = getters.getFeedbacksByDoor(doorId);
        let relevantFeedbacks = [];

        for (let i = 0; i < feedbacks.length; i++) {
            if (feedbacks[i].author.id === Number(authorId)) {
                relevantFeedbacks.push(feedbacks[i]);
            }
        }

        return relevantFeedbacks;
    },
    getFeedbacksByTourAndAuthor: (state, getters) => (authorId, tourID) => {
        const feedbacks = getters.getFeedbacksByTour(tourID);
        let relevantFeedbacks = [];

        if (feedbacks) {
            for (let i = 0; i < feedbacks.length; i++) {
                if (feedbacks[i].author.id === Number(authorId)) {
                    relevantFeedbacks.push(feedbacks[i]);
                }
            }
        }

        return relevantFeedbacks;
    }
};

const mutations = {
    setFeedbacks(state, feedbacks) {
        state.feedbacks = feedbacks;
    },
    setFeedbacksByDoors(state, feedbacks) {
        let feedbacksDoors = {};
        for (var i = 0; i < feedbacks.length; i++) {
            if (!feedbacks[i].door) continue;
            let doorId = feedbacks[i].door.id;
            if (!feedbacksDoors[doorId]) {
                feedbacksDoors[doorId] = [];
            }
            feedbacksDoors[doorId].push(feedbacks[i].id);
        }
        state.feedbacksDoors = feedbacksDoors;
    },
    setDoorFeedbackNumber(state, data) {
        state.doorFeedbackNumbers.push({ doorId: data.doorId, tourId: data.tourId, alert: data.alert, normal: data.normal });
    },
    setFeedbackTypes(state, data) {
        state.feedbackTypes.push({ tourNumber: data.tourNumber, alert: data.alert, normal: data.normal });
    },
    addFeedback(state, feedback) {
        // todo: structure of state.feedbacksDoors is not the same as feedback object
        // if (feedback.door) {
        //     state.feedbacksDoors.push(feedback);
        // }
        state.feedbacks.push(feedback);
    },
    updateDoorFeedbackTypesCount(state, {doorId, tourId, alert, action}) {
        const tourIndex = state.doorFeedbackNumbers.findIndex(item => {
            return item.doorId === parseInt(doorId) && item.tourId === parseInt(tourId)
        });
        let countAlert = state.doorFeedbackNumbers[tourIndex].alert;
        let countNormal = state.doorFeedbackNumbers[tourIndex].normal;

        if (alert) {
            if (action === 'deleted') {
                countAlert -= 1;
            } else if (action === 'added') {
                countAlert += 1;
            } else {
                countAlert += 1;
                if (countNormal > 0) countNormal -= 1;
            }
        } else {
            if (action === 'deleted') {
                countNormal -= 1;
            } else if (action === 'added') {
                countNormal += 1;
            } else {
                countNormal += 1;
                if (countAlert > 0) countAlert -= 1;
            }
        }

        state.doorFeedbackNumbers[tourIndex] = {doorId: parseInt(doorId), tourId: parseInt(tourId), alert: countAlert, normal: countNormal};
    },
    updateFeedbackTypesCount(state, { tourId, alert, action }) {
        const tourIndex = state.feedbackTypes.findIndex(item => item.tourNumber === tourId);
        if (tourIndex !== -1) {
            let countAlert = state.feedbackTypes[tourIndex].alert;
            let countNormal = state.feedbackTypes[tourIndex].normal;

            if (alert) {
                if (action === 'deleted') {
                    countAlert -= 1;
                } else if (action === 'added'){
                    countAlert += 1;
                } else {
                    countAlert += 1;
                    if (countNormal > 0) countNormal -= 1;
                }
            } else {
                if (action === 'deleted') {
                    countNormal -= 1;
                } else if (action === 'added'){
                    countNormal += 1;
                } else {
                    countNormal += 1;
                    if (countAlert > 0) countAlert -= 1;
                }
            }

            state.feedbackTypes[tourIndex] = {tourNumber: tourId, alert: countAlert, normal: countNormal};
        }
    },
    updateFeedbackEntry(state, updatedFeedback) {
        const oldFeedbackIndex = state.feedbacks.findIndex(feedback => feedback.id === updatedFeedback.id);

        state.feedbacks[oldFeedbackIndex] = updatedFeedback;
    },
    updateFeedbackByTourEntry(state, updatedFeedback) {
        let oldFeedbackIndex = null;
        for (let i = 0; i < state.feedbacksByTour.length; i++) {
            oldFeedbackIndex = state.feedbacksByTour[i].feedbacks.findIndex(feedback => feedback.id === updatedFeedback.id);
            if (oldFeedbackIndex > -1) {
                state.feedbacksByTour[i].feedbacks[oldFeedbackIndex] = updatedFeedback;
            }
        }
    },
    addFeedbackToDoor(state, data) {
        let feedbackId = data.resId;
        let doorId = data.doorId;

        if (!state.feedbacksDoors[doorId]) {
            state.feedbacksDoors[doorId] = [feedbackId];
        } else {
            state.feedbacksDoors[doorId].push(feedbackId);
        }
    },
    removeFeedback(state, feedbackID) {
        const feedbackIndex = state.feedbacks.findIndex(item => item.id === feedbackID);
        state.feedbacks.splice(feedbackIndex, 1);
    },
    removeFeedbackByTour(state, feedbackID) {
        let feedbackIndex = null;
        for (let i = 0; i < state.feedbacksByTour.length; i++) {
            feedbackIndex = state.feedbacksByTour[i].feedbacks.findIndex(item => item.id === feedbackID);
            if (feedbackIndex > -1) {
                state.feedbacksByTour[i].feedbacks.splice(feedbackIndex, 1);
            }
        }
    },
    setFeedbacksByTour(state, { tourID, feedbacks }) {
        state.feedbacksByTour.push({ tourID: tourID, feedbacks: feedbacks });
    },
    setFeedbacksByTourAndAuthor(state, { authorId, tourId, feedbacks }) {
        state.feedbacksByTour.push({ authorId: authorId, tourID: tourId, feedbacks: feedbacks });
    },
    addFeedbackToTour (state, params) {
        const tourIndex = state.feedbacksByTour.findIndex(tour => tour.tourID === params.tourId);
        state.feedbacksByTour[tourIndex].feedbacks.push(params.feedback);
    },
    reset(state) {
        Object.assign(state, getDefaultState());
    }
};

const actions = {
    async loadFeedbacksByAuthorAndCity({ commit, state, rootState }, { authorId, cityId } = {}) {
        authorId = authorId || rootState.user.userInfo.id;
        cityId = cityId || rootState.city.currentCity.id;

        if (state.feedbacks.length) {
            return state.feedbacks.filter(fb => fb.author.id === authorId);
        } else {
            let feedbacks = await api.request("feedback", {
                "author.id": authorId,
                "tour.city.id": cityId //FIXME: allows filters tour.city.id ===cityId || door.tradezone.city.id === cityId
            });

            // TODO: IN API => allow filtering by city even if tour is null
            // POSSIBLE FRONT-END WORKAROUND: (NOT WORKING BECAUSE fb.tour.city.id and fb.door.tradezone.city.id are not exposed by api)
            // feedbacks = feedbacks.filter(fb => fb.tour && fb.tour.city && fb.tour.city.id === cityId ||
            //                              fb.door && fb.door.tradezone.city.id === cityId);

            commit("setFeedbacks", feedbacks);
            commit("setFeedbacksByDoors", feedbacks);
            return feedbacks;
        }
    },
    async loadFeedbacksByTour({ commit, getters }, tourID) {
        let feedbacks = getters.getFeedbacksByTour(tourID);
        if (!feedbacks) {
            feedbacks = await api.request("feedback", {
                "tour.id": tourID
            });

            commit("setFeedbacksByTour", { tourID, feedbacks });
        }

        return feedbacks;
    },
    loadFeedBackCount({ commit, rootState }, { tours } = {}) {        
        tours = tours || rootState.tour.availableTours;

        if (tours.length) {
            tours.forEach(tour => {
                let tourAlertFbCount = 0;
                let tourNormalFbCount = 0;
                let tourAlertFbWithoutDoorCount = 0;
                let tourNormalFbWithoutDoorCount = 0;

                if (tour.feedbacks) {
                    Object.keys(tour.feedbacks).forEach(key => {
                        const tourFbCounts = tour.feedbacks[key];
                        const doorId = parseInt(key);
                        if (doorId === 0) {
                            // Feedback without door
                            tourAlertFbWithoutDoorCount += parseInt(tourFbCounts.alert);
                            tourNormalFbWithoutDoorCount += parseInt(tourFbCounts.normal);
                        } else {
                            tourAlertFbCount += parseInt(tourFbCounts.alert);
                            tourNormalFbCount += parseInt(tourFbCounts.normal);
                            commit("setDoorFeedbackNumber", {
                                doorId: parseInt(doorId),
                                tourId: parseInt(tour.id),
                                alert: parseInt(tourFbCounts.alert),
                                normal: parseInt(tourFbCounts.normal)
                            });
                        }
                    });

                    commit("setFeedbackTypes", {
                        tourNumber: tour.id,
                        normal: tourNormalFbCount + tourNormalFbWithoutDoorCount,
                        alert: tourAlertFbCount + tourAlertFbWithoutDoorCount
                    });
                }
            });
        }
    },
    async loadFeedbacksByTourAndAuthor({ commit, getters, rootState }, params) {
        let feedbacks = getters.getFeedbacksByTourAndAuthor(params.authorId, params.tourId);
        let authorId = params.authorId || rootState.user.userInfo.id;
        let tourId = params.tourId;

        if (!feedbacks.length > 0) {
            feedbacks = await api.request("feedback", {
                "author.id": authorId,
                "tour.id": tourId
            });

            if (feedbacks.length > 0) {
                commit("setFeedbacksByTourAndAuthor", { authorId, tourId, feedbacks });
            }
        }

        return feedbacks;
    },
    loadFeedbacksByDoor({ commit, getters }, doorID) {
        return new Promise((resolve, reject) => {
            let feedbacks = getters.getFeedbacksByDoor();
            if (feedbacks.length) {
                resolve(feedbacks);
            } else {
                api.request(
                    "feedback",
                    {
                        "door.id": doorID
                    },
                    feedbacks => {
                        commit("setFeedbacks", feedbacks);
                        commit("setFeedbacksByDoors", feedbacks);
                        resolve(feedbacks);
                    },
                    error => {
                        reject(error);
                    }
                );
            }
        });
    },
    createFeedback({ commit, dispatch, state }, { alert, description, authorId, author, doorId, tourId, images }) {
        return new Promise((resolve, reject) => {
            api.post(
                "feedback",
                {
                    alert: alert,
                    description: description,
                    author: "/api/users/" + authorId,
                    door: doorId ? "/api/doors/" + doorId : null,
                    tour: tourId ? "/api/tours/" + tourId : null,
                    images: images
                },
                async res => {
                    let updated = res;

                    updated.author.fullname = author;

                    commit("addFeedback", updated);
                    if (res.door) {
                        commit("addFeedbackToDoor", { resId: res.id, doorId: doorId });
                    }

                    if (res.tour) {
                        if(!state.feedbacksByTour.length || !state.feedbacksByTour.find(tour => tour.tourID === tourId)) {
                            await dispatch('loadFeedbacksByTour', tourId);
                        } else {
                            commit("addFeedbackToTour", { feedback: res, tourId: tourId });
                        }
                    }

                    commit("tour/updateAllUserTours", {alert: alert, doorId: doorId, tourId: tourId}, { root: true });
                    resolve(res);
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    // eslint-disable-next-line
    createImage({}, file) {
        return new Promise((resolve, reject) => {
            api.post(
                "images",
                file,
                res => {
                    resolve(res);
                },
                error => {
                    reject(error);
                },
                "multipart/form-data"
            );
        });
    },
    updateFeedback({ commit }, { alert, description, authorId, author, doorId, tourId, images, feedbackId }) {
        return new Promise((resolve, reject) => {
            api.put(
                "feedback/" + feedbackId,
                {
                    alert: alert,
                    description: description,
                    author: "/api/users/" + authorId,
                    door: doorId ? "/api/doors/" + doorId : null,
                    tour: tourId ? "/api/tours/" + tourId : null,
                    images: images
                },
                res => {
                    let updated = res.data;

                    updated.author.fullname = author;
                    commit("updateFeedbackByTourEntry", updated);
                    commit("updateFeedbackEntry", updated);
                    resolve(res);
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    updateFeedbackTypes({ commit }, {tourId, doorId, alert, action = null} = {}) {
        commit("updateFeedbackTypesCount", {tourId: tourId, alert: alert, action: action});
        commit("updateDoorFeedbackTypesCount", {doorId: doorId, tourId: tourId, alert: alert, action: action});
    },
    deleteFeedback({ commit }, feedbackID) {
        return new Promise((resolve, reject) => {
            api.delete(
                "feedback",
                {
                    id: feedbackID
                },
                res => {
                    commit("removeFeedbackByTour", feedbackID);
                    commit("removeFeedback", feedbackID);
                    resolve(res);
                },
                error => {
                    reject(error);
                }
            );
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
    modules: {}
};
