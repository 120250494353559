import { Campaigns, InspirationalPlace } from "../sanitize";

const getDefaultState = () => {
    return {
        currentCity: null,
        allCities: [],
        categories: null,
        mindsets: [],
        consumer: [],
        campaigns: [],
        lastSelectedCity: { userId: null, cityId: null },
        highlightedDestinations: [],
        cityInspirationalPlaces: [],
        events: []
    };
};
const state = getDefaultState();

const getters = {
    getMindset: state => id => {
        return state.mindsets ? state.mindsets.find(mindset => mindset.id === id) : null;
    },
    getConsumer: state => id => {
        return state.consumer ? state.consumer.find(consumer => consumer.id === id) : null;
    },
    getCampaign: state => id => {
        return state.campaigns ? state.campaigns.find(campaign => campaign.id === id) : null;
    }
};

const mutations = {
    setCurrentCity: (state, city) => {
        state.currentCity = city;
    },
    setLastSelectedCity: (state, { userId, cityId }) => {
        state.lastSelectedCity = { userId, cityId };
    },
    setCategories(state, val) {
        state.categories = val;
    },
    setMindsets(state, mindsets) {
        state.mindsets = mindsets;
    },
    setMindset(state, mindsets) {
        if (state.mindsets) {
            state.mindsets.push(mindsets);
        }
    },
    setConsumer(state, consumer) {
        state.consumer.push(consumer);
    },
    setCampaign(state, campaigns) {
        state.campaigns.push(campaigns);
    },
    setCampaigns(state, campaigns) {
        state.campaigns = campaigns;
    },
    setAllCities(state, allCities) {
        state.allCities = allCities;
    },
    reset(state) {
        const lastSelectedCity = state.lastSelectedCity;
        Object.assign(state, getDefaultState());
        state.lastSelectedCity = lastSelectedCity;
    },
    setHighlightedDestinations(state, parameters) {
        const filterIndex = state.highlightedDestinations.findIndex(highlight => highlight.id === parameters.filterId);
        state.highlightedDestinations[filterIndex].highlights = parameters.destinationData;
    },
    setHighlightedDestinationsFilters(state, filters) {
        state.highlightedDestinations = filters.map(filter => ({ id: filter.id, highlights: [] }));
    },
    setCityInspirationPlaces(state, places) {
        state.cityInspirationalPlaces = places;
    },
    setEvents(state, events) {
        state.events = events
    },
};

const actions = {
    async loadHighlightedDestinations({ commit, state }, filterId) {
        const destinationData = await api.request("cities/" + state.currentCity.id + "/highlighted");
        commit("setHighlightedDestinations", { destinationData, filterId }, {});

        return destinationData
    },
    async loadAllCities({ commit, state }) {
        if (!state.allCities.length) {
            commit("setAllCities", await api.request("cities", {}));
        }
        return state.allCities;
    },
    //eslint-disable-next-line
    async loadCityById({}, params) {
        if (!params && params.id) throw new Error("params.id missing");
        return api.request("cities/" + params.id, {});
    },
    async loadCategoriesByCity({ commit, state }, { cityId } = {}) {
        cityId = cityId || state.currentCity.id;

        const categories = await api.request("categories", {
            "city.id": cityId,
            "cities.id": cityId
        });

        const sortedCategories = categories.sort((a, b) => a.position - b.position);

        if (!state.currentCity.features.consumers) {
            for (let i = 0; i < sortedCategories.length; i++) {
                if (sortedCategories[i].mode === "consumers") {
                    sortedCategories.splice(i, 1);
                }

                if (sortedCategories[i].position !== i + 1) {
                    sortedCategories[i].position = i + 1;
                }
            }
        }

        commit("setCategories", sortedCategories);

        return state.categories;
    },
    loadCampaignById({ commit, getters }, params) {
        return new Promise((resolve, reject) => {
            let campaign = getters.getCampaign(params.id);
            if (campaign) {
                resolve(campaign);
            } else {
                api.request(
                    "campaigns/" + params.id,
                    {},
                    campaign => {
                        commit("setCampaign", campaign);
                        resolve(campaign);
                    },
                    error => {
                        reject(error);
                    }
                );
            }
        });
    },

    async loadCampaignsByCity({ state }, { param, order, cityId } = {}) {
        cityId = cityId || state.currentCity.id;

        const campaigns = await api.request("campaigns", {
            ...param,
            "city.id": cityId,
            "cities.id": cityId
        });

        if (order) {
            return Campaigns(campaigns);
        } else {
            return campaigns;
        }
    },

    async loadMindsetsByCity({ commit, state }, { cityId } = {}) {
        cityId = cityId || state.currentCity.id;

        if (!state.mindsets) {
            const mindsets = await api.request("mindsets", {
                "consumers.city.id": cityId
            });
            commit("setMindsets", mindsets);
        }

        return state.mindsets;
    },
    loadMindsetById({ commit, getters }, params) {
        return new Promise((resolve, reject) => {
            let mindset = getters.getMindset(params.id);
            if (mindset) {
                resolve(mindset);
            } else {
                api.request(
                    "mindsets/" + params.id,
                    {},
                    mindset => {
                        commit("setMindset", mindset);
                        resolve(mindset);
                    },
                    error => {
                        reject(error);
                    }
                );
            }
        });
    },
    loadConsumersById({ commit, getters }, params) {
        return new Promise((resolve, reject) => {
            let consumer = getters.getConsumer(params.id);
            if (consumer) {
                resolve(consumer);
            } else {
                api.request(
                    "mindsets/" + params.id,
                    {},
                    consumer => {
                        commit("setConsumer", consumer);
                        resolve(consumer);
                    },
                    error => {
                        reject(error);
                    }
                );
            }
        });
    },
    // eslint-disable-next-line
    loadInspirationalPlacesdWithFilters({ state }, params) {
        return new Promise((resolve, reject) => {
            api.request(
                "inspirational-places",
                { ...params.params, "city.id": state.currentCity.id, "cities.id": state.currentCity.id },
                places => {
                    if (params.order) {
                        const orderPlaces = InspirationalPlace(places);
                        resolve(orderPlaces);
                    } else {
                        resolve(places);
                    }
                },
                error => {
                    reject(error);
                },
                10
            );
        });
    },
    async loadInspirationPlacesByCity({ state, commit }, order) {
        if (!state.cityInspirationalPlaces.length) {
            const places = await api.request("cities/" + state.currentCity.id + "/inspirational-places", {});

            if (order) {
                commit("setCityInspirationPlaces", InspirationalPlace(places));
            } else {
                commit("setCityInspirationPlaces", places);
            }
        }

        return state.cityInspirationalPlaces;
    },
    fetchEvents({ commit }) {
        return new Promise((resolve, reject) => {
            api.request("events/", {},
                events => {
                    commit("setEvents", events);
                    resolve(events);
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    reset() {}
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
    modules: {}
};
